import * as types from "./mutation-types";

export default {
  [types.SET_SHOW_LOADER]: (state, { value }) => {
    state.showLoader = value;
  },
  [types.GET_COUPON_DETAILS]: (state, { value }) => {
    state.coupon = value;
  },
  [types.GET_OFFER_COUPON_DETAILS]: (state, { value }) => {
    state.offerCoupon = value;
  },
  [types.GET_STORE_SEARCH_DETAILS]: (state, { value }) => {
    state.storeSearch = value;
  },
  [types.GET_POPULAR_COUPON_DETAILS]: (state, { value }) => {
    state.popularCoupon = value;
  },
  [types.GET_STORE_SEARCH_PROFILE_DETAILS]: (state, { value }) => {
    state.storeProfileSearch = value;
  },
  [types.GET_TOP_STORES_DETAILS]: (state, { value }) => {
    state.topStores = value;
  },
  [types.STORE_HEADER]: (state, { value }) => {
    state.header = value;
  },
  [types.FOOTER]: (state, { value }) => {
    state.footer = value;
  },
};
