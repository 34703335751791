let configurations;

if (process.env.NODE_ENV === "development") {
  configurations = {
    apiUrl: "https://api.bisharafak.com/api/",
    apiUrl2: "https://api.bisharafak.com/api",
  };
} else {
  configurations = {
    apiUrl: "https://api.bisharafak.com/api/",
    apiUrl2: "https://api.bisharafak.com/api",
  };
}
export default configurations;
