import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/offer",
    name: "Offer",
    component: Home,
  },
  {
    path: "/stores",
    name: "StorePage",
    component: () =>
      import(/* webpackChunkName: "store" */ "../views/AllStores.vue"),
  },
  {
    path: "/store/:slug",
    name: "SearchStore",
    component: () =>
      import(/* webpackChunkName: "store" */ "@/views/Store.vue"),
  },
  {
    path: "/store/:slug/:couponId/zrwr",
    name: "redirectCoupon_zrwr",
    component: () =>
      import(/* webpackChunkName: "StoreCouponRedirect" */ "@/views/StoreCouponRedirect.vue"),
  },
  {
    path: "/store/:slug/:couponId/zrwor",
    name: "redirectCoupon_zrwor",
    component: () =>
      import(/* webpackChunkName: "StoreCouponRedirect" */ "@/views/StoreCouponRedirect.vue"),
  },
  {
    path: "/coupon",
    name: "Coupon",
    component: () =>
      import(/* webpackChunkName: "coupon" */ "../views/Coupon.vue"),
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: () =>
      import(/* webpackChunkName: "coupon" */ "../views/PrivacyPolicy.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    component: () =>
      import(/* webpackChunkName: "coupon" */ "../views/Contact.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "coupon" */ "../views/AboutUs.vue"),
  },
  {
    path: "*",
    name: "notFound",
    component: () =>
      import(/* webpackChunkName: "coupon" */ "../views/NotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
