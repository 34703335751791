import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

const state = {
  showLoader: false,
  CouponDetail: [],
  redirectCounter: 5,
  offerCoupon: [],
  storeSearch: [],
  popularCoupon: [],
  storeProfileSearch: [],
  topStores: [],
  header: [],
  rtl_mode: 0,
  coupon_link: null,
  footer: {},
  isHeadTagUpdated: false
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
