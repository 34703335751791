import axios from "axios";
import config from "../config";
import store from "../store/index";

const api2 = axios.create({
  baseURL: config.apiUrl2,
  responseType: "json",
});

// Add a request interceptor
api2.interceptors.request.use(
  function(config) {
    const token = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : false;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a request interceptor
api2.interceptors.request.use(
  function(config) {
    config.headers["domain-name"] = "bisharafak.com";
    config.headers["domain"] = "bisharafak.com";
    config.headers["is-bisharafak"] = true;
    const token = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : false;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
api2.interceptors.response.use(
  function(response) {
    //console.log("response", response);
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function(error) {
    //console.log("error", error.response.data.message);
    if (error.response.data.message === "access_denied") {
      store.commit("user/SET_MESSAGE", {
        value:
          "Your account has been deactivated, please contact admin for more informacion",
      });
      store.dispatch("user/logout");
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);
export default api2;
