import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const messages = {
  en: {
    copyExclusiveCoupon: "Click Below To Get Your Exclusive Coupon Code",
    copySpecialCoupon: "Click Below To Get Your Special Coupon Code",
    copyCouponCode: "Copy Coupon Code",
    copyCoupon: "Copy Coupon",
    redirectingIn5Sec1: "Redirecting in ",
    redirectingIn5Sec2: "seconds",
    redirectingToStoreIn5Sec: "Taking You To The Store In 5 seconds",
    redirectingToStore: "redirecting you to the store",
    copy: "Copy",
  },
  ar: {
    copyExclusiveCoupon: "إضغط هنا للحصول علي  كوبون خصم مميز",
    copySpecialCoupon: "أضغط هنا  لتحصل علي الكوبون الخصم  الخاص بك",
    copyCouponCode: "نسخ  كود الخصم",
    copyCoupon: "انسخ الكوبون",
    // redirectingIn5Sec:'سيتم تحويلك في خلال خمس ثواني',
    redirectingIn5Sec1: "سيتم نقلك في غضون",
    redirectingIn5Sec2: "ثوان",
    redirectingToStoreIn5Sec: "جاري دخول المتجر في خمس ثواني",
    redirectingToStore: "جاري دخول المتجر الآن",
    copy: "ينسخ",
  },
};

const i18n = new VueI18n({
  locale: "en", // set locale
  fallbackLocale: "ar", // set fallback locale
  messages, // set locale messages
});
export default i18n;
