import * as types from "./mutation-types";
import api from "../../../utils/api";
import api2 from "../../../utils/api2";
import router from "../../../router";
import axios from 'axios';

export default {
  fetchCoupon: ({
    commit
  }) => {
    return (
      api
      //.post(`https://installmentloannbbm.com/dashboard/api/coupon/getDetails?id=identifier`)
      .post(`dashboard/api/coupon/getDetails?id=identifier`)
      .then((response) => {
        if (response.status === 200) {
          commit(types.GET_COUPON_DETAILS, {
            value: response.data.data
          });
        }
        return response;
      })
      .catch((error) => {
        return error;
      })
    );
  },

  fetchCouponForStore: ({
    commit,
    state
  }, data) => {
    return api2
      .post(`/coupon/fetch`, data)
      .then((response) => {
        if (response.status === 200) {
          commit(types.GET_OFFER_COUPON_DETAILS, {
            value: response.data
          });
        }
        return response;
      })
      .catch((error) => {
        return error;
      });
  },

  fetchSearchInStore: ({
    commit,
    state
  }, data) => {
    // commit(types.SET_SHOW_LOADER, { value: true });
    return api2
      .get(`/end-user/coupon/coupons-by-storeid?storeId=${data.slug}&perPage=50&currentPage=1&resolution=${data.resolution}`)
      // .get(`/end-user/store/getStoreByIdAndAllItsCoupons?storeId=${data.slug}`)
      .then((response) => {
        // commit(types.SET_SHOW_LOADER, { value: false });
        // console.log("response", response.data.store);
        if (response.status === 200) {
          commit(types.GET_STORE_SEARCH_DETAILS, {
            value: response.data.data.coupons,
          });
          if (response.data.data) {
            commit(types.GET_STORE_SEARCH_PROFILE_DETAILS, {
              value: response.data.data,
            });
          }
        }
        return response;
      })
      .catch((error) => {
        // commit(types.SET_SHOW_LOADER, { value: false });
        return error;
      });
  },

  fetchPopularCoupon: ({
    commit
  }, payload) => {
    // commit(types.SET_SHOW_LOADER, { value: true });
    return api2
      .get(`/end-user/coupon/getCouponsBasedOnDomain`,{params: payload})
      .then((response) => {
        // commit(types.SET_SHOW_LOADER, { value: false });
        $(".main-slider").owlCarousel({
          items: 1,
          loop: !1,
          center: !0,
          margin: 10,
          autoplayHoverPause: !0,
          dots: !1,
          nav: !1,
        });
        if (response.status === 200) {
          commit(types.GET_POPULAR_COUPON_DETAILS, {
            value: response.data.data,
          });
        }
        return response;
      })
      .catch((error) => {
        // commit(types.SET_SHOW_LOADER, { value: false });
        return error;
      });
  },

  fetchTopStores: ({
    commit
  }) => {
    return api2
      .get(`/end-user/store/getStoresBasedOnDomain`)
      .then((response) => {
        if (response.status === 200) {
          commit(types.GET_TOP_STORES_DETAILS, {
            value: response.data.data,
          });
        }
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  fetchBanners: ({
    commit
  }) => {
    return (
      api2
      // .get(`/header/get`)
      .get(`/banner/get`)
      .then((response) => {
        if (response.status === 200) {
          return response.data.data;
        }
        return [];
      })
      .catch((error) => {
        return error;
      })
    );
  },
  fetchHeaders: ({
    commit
  }) => {
    return api2
      .get(`/header/get`)
      .then((response) => {
        if (response.status === 200) {
          commit(types.STORE_HEADER, {
            value: response.data.data
          });
          return response.data.data;
        }
        return [];
      })
      .catch((error) => {
        return error;
      });
  },
  fetchFooter: ({ commit }) => {
    let one = "https://api.bisharafak.com/api/websiteData/fetchFooterJS"
    let two = "https://api.bisharafak.com/api/websiteData/fetchByDomain"
    const requestOne = api2.get(one);
    const requestTwo = api2.get(two);

    axios.all([requestOne, requestTwo]).then(axios.spread((...responses) => {
      const responseOne = responses[0]
      const responseTwo = responses[1]

      let footerJs = {};
      let footerConfig = {};
      if (responseOne.status === 200) {
       footerJs = responseOne.data.data
      }

      if (responseOne.status === 200) {
        footerConfig = responseTwo.data.data;
      }
     commit(types.FOOTER, {value: {'footerJs': footerJs, 'footerConfig': footerConfig}});

    })).catch((error) => {
      return error;
    });
  },
  couponUrl: ({ commit }, data) => {
    let url = "https://api.bisharafak.com/v2/fetch/couponurl"
        api2.post(url, data).then((response) => {
         return response
        })
  },
  googleVerification: ({ commit }) => {
    let url = "https://api.bisharafak.com/api/end-user/website-data/google-verification-tag"
    return api2.get(url)
  }
};
