<template>
<div>
    <!-- Navigation Bar-->
    <navbar></navbar>
    <!-- Navigation ends -->
    <div class="wrapper">
        <div>
            <div class="container">
                <div class="row">
                    <div class="col-sm-12">
                        <!-- Main component for a primary marketing message or call to action -->
                        <div class="slide-wrap shadow">
                            <Slider :images="banners" style="height:500px" />
                            <div class="carousel-tabs clearfix" v-if="popularCouponData">
                                <carousel-tabs v-for="(card, i) in popularCouponData.slice(0, 3)" :key="i" :card="card" />
                            </div>
                        </div>
                        <!--/slide wrap -->
                    </div>
                    <!-- /col 12 -->
                </div>
                <div class="row">
                    <div class="col-lg-8">
                        <ul class="nav nav-tabs responsive-tabs" id="myTab">
                            <li class="active">
                                <a data-toggle="tab" href="#popular" @click="callPopular">
                                    <i class="ti-bar-chart"></i>Popular
                                </a>
                            </li>
                            <li class>
                                <a data-toggle="tab" href="#ending" @click="callMostUsed">
                                    <i class="ti-timer"></i> Most Used
                                </a>
                            </li>
                        </ul>

                        <div class="tab-content clearfix" id="myTabContent">
                            <div id="popular" class="tab-pane counties-pane active animated fadeIn">
                                <Spinner v-if="loader===true" name="wave" />
                                <card v-for="(carddetail, i) in popularCouponData" :key="i" :carddetail="carddetail" @click-get-code="clickGetCode">
                                </card>
                                <!-- end: coupon wrap -->
                            </div>
                            <div id="ending" class="tab-pane counties-pane animated fadeIn">
                                <card v-for="(carddetail, i) in popularCouponData" :key="i" :carddetail="carddetail" @click-get-code="clickGetCode"></card>
                                <!-- end: coupon wrap -->
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="widget">
                            <!-- /widget heading -->
                            <div class="widget-heading">
                                <h3 class="widget-title text-dark">Top Stores</h3>
                                <div class="clearfix"></div>
                            </div>
                            <div class="widget-body">
                                <div class="row" v-if="topStores">
                                    <display-stores-horizontally v-for="(top, i) in topStores.slice(0,10)" :key="i" :store="top"></display-stores-horizontally>
                                    <!-- /thumb -->
                                </div>
                            </div>
                        </div>

                        <section class="call-to-action">
                            <div class="">
                                <div class="row explain_group">
                                    <div class="col-md-12 mb5">
                                        <a class="item" rel="nofollow" href="javascript://">
                                            <div class="box-icon">
                                                <i class="bg-danger ti-search"></i>
                                            </div>
                                            <div class="box-info">
                                                <h3>Search coupons</h3>
                                                <h4>Find best money-saving coupons.</h4>
                                                <div class="point">
                                                    <i class="ti-check"></i>
                                                    <span>Find fresh coupons</span>
                                                </div>
                                                <div class="point">
                                                    <i class="ti-check"></i>
                                                    <span>Top Coupons & Offers</span>
                                                </div>
                                            </div>
                                        </a>
                                    </div>

                                    <div class="col-md-12 mb5">
                                        <a class="item" rel="nofollow" href="javascript://">
                                            <div class="box-icon">
                                                <i class="bg-info ti-shopping-cart-full"></i>
                                            </div>
                                            <div class="box-info">
                                                <h3>Save your money</h3>
                                                <h4>Find best money-saving coupons.</h4>
                                                <div class="point">
                                                    <i class="ti-check"></i>
                                                    <span>Find fresh coupons</span>
                                                </div>
                                                <div class="point">
                                                    <i class="ti-check"></i>
                                                    <span>Top Coupons & Offers</span>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div class="col-md-12 mb5">
                                        <a class="item" rel="nofollow" href="javascript://">
                                            <div class="box-icon">
                                                <i class="bg-purple ti-gift"></i>
                                            </div>
                                            <div class="box-info">
                                                <h3>Earn your gifts</h3>
                                                <h4>Find best money-saving coupons.</h4>
                                                <div class="point">
                                                    <i class="ti-check"></i>
                                                    <span>Find fresh coupons</span>
                                                </div>
                                                <div class="point">
                                                    <i class="ti-check"></i>
                                                    <span>Top Coupons & Offers</span>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <!-- end col -->
                </div>
                <!-- End row -->

                <text-section></text-section>

            </div>

            <section class="newsletter-alert"></section>
            <!-- Footer -->
            <foot />
        </div>
        <!-- end:Newsletter signup -->

        <!-- start modal -->
        <coupon-offer-modal :eachCouponOffer="eachCouponOffer">
        </coupon-offer-modal>
        <!-- end: Modall -->
    </div>
</div>
<!-- //wrapper -->
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

import {
    mapState
} from "vuex";
import {
    commonCopyMixin
} from "../components/Mixins/commonCopyMixin";
import api2 from "../utils/api2";

export default {
    name: "Home",
    mixins: [commonCopyMixin],
    components: {
        DisplayStoresHorizontally: () => import( /* webpackPrefetch: true */ '../components/contact/DisplayStoresHorizontally'),
        TextSection: () => import( /* webpackPrefetch: true */ '../components/home/TextSection'),
        Foot: () => import( /* webpackPrefetch: true */ '../components/core/Footer'),
        CarouselTabs: () => import( /* webpackPrefetch: true */ '../components/home/CarouselTabs'),
        Navbar: () => import( /* webpackPrefetch: true */ '../components/core/Navbar'),
        TopStore: () => import( /* webpackPrefetch: true */ '../components/home/TopStore'),
        Card: () => import( /* webpackPrefetch: true */ '../components/home/Card'),
        CouponOfferModal: () => import( /* webpackPrefetch: true */ '../components/common/CouponOfferModal'),
        Slider: () => import( /* webpackPrefetch: true */ '../components/common/Slider')
    },
    data() {
        return {
            eachCouponOffer: [],
            textToCopy: null,
            loader: true,
            window: {
                width: 0,
                height: 0
            }
        };
    },
    async created() {
        this.loader = true;
        window.addEventListener('resize', this.handleResize);
        this.handleResize();

        await this.onLoadPopularCoupon();
        this.$store.dispatch("coupon/fetchTopStores")
        this.$store.dispatch('coupon/googleVerification')
    },
    async mounted() {
        this.loader = false;
    },
    computed: {
        ...mapState({
            popularCoupon: (state) =>
                state.coupon.popularCoupon ? state.coupon.popularCoupon : [],
            topStores: (state) =>
                state.coupon.topStores ? state.coupon.topStores : [],
            showLoader: (state) => state.coupon.showLoader,
        }),
        popularCouponData() {
            // let coupons = this.$store.state.coupon.popularCoupon;
            // return coupons.filter(val => val.is_visible === true)
            return this.$store.state.coupon.popularCoupon;
        },
        banners() {
            if (this.$store.state.coupon.footer.hasOwnProperty('footerConfig') &&
                this.$store.state.coupon.footer.footerConfig.hasOwnProperty('banners_v2')
            ) {
                return this.$store.state.coupon.footer.footerConfig.banners_v2;
            }
            return ''
        }
    },

    methods: {
        callMostUsed() {
            this.onLoadPopularCoupon();
        },
        callPopular() {
            this.onLoadPopularCoupon();
        },
        async onLoadPopularCoupon() {
            await this.$store.dispatch("coupon/fetchPopularCoupon", {
                limit: 20,
                resolution:  this.window.width + 'x' + this.window.height
            }).then((res) => {});
        },
        destroyed() {
            window.removeEventListener('resize', this.handleResize);
        },
        async clickGetCode(value) {
            this.eachCouponOffer = value;

            if (value.coupon_code) {
                this.textToCopy = value.coupon_code;
            } else {
                this.textToCopy = "ABC2020";
            }

            // let url = "https://api.bisharafak.com/v2/fetch/couponurl"
            // await api2.post(url, {
            //     coupon_id: value._id,
            //     resolution: this.window.width + 'x' + this.window.height,
            //     lpURL: document.URL,
            // }).then((response) => {
            //     if (response.data) {
            //         let data = response.data
            //         if (data.success = true) {
            //             value.link = data.link
            //         }
            //     }
            // })
            value.link = value.finalLink;

            this.$store.state.coupon_link = value.finalLink;
            this.$store.state.couponCopyed = 1;
            await this.commonCopy();
        },
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        }
    }
};
</script>

<style scoped>
.img-container {
    width: 100%;
    object-fit: contain;
    /* aspect-ratio: 16 / 9; 16:9 Aspect Ratio */
}

.sk-spinner.sk-wave {
    margin: auto;
}

.mb5 {
    margin-bottom: 5px;
}
</style>
