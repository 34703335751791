import axios from "axios";
import config from '../config';
import store from './../store/index';


const api = axios.create({
    baseURL: config.apiUrl,
    responseType: "json"
});

// Add a request interceptor
api.interceptors.request.use(
    function(config) {
        const token = (localStorage.getItem('token')) ? localStorage.getItem('token') : false
        if (token) {
          config.headers.Authorization = `Bearer ${token}`
        }
        return config;
    },
    function(error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

// Add a response interceptor
api.interceptors.response.use(
    function(response) {
        //console.log("response", response);
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    },
    function(error) {
        //console.log("error", error.response.data.message);
        if(error.response.data.message === 'access_denied') {
            store.commit('user/SET_MESSAGE', { value: "Your account has been deactivated, please contact admin for more informacion" });
            store.dispatch("user/logout");
        }
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
    }
);
export default api;
