import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "@/assets/css/icons.css";
import VueToastify from "vue-toastify";
import i18n from "@/plugins/i18n";
import Spinner from "vue-spinkit";

Vue.component("Spinner", Spinner);
Vue.use(VueToastify);
Vue.config.productionTip = false;
Vue.prototype.$base_domain = "https://api.bisharafak.com/";
Vue.prototype.$base_url = "https://api.bisharafak.com/api/";
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

//require('./assets/js/jquery.min.js');
//require('./assets/js/bootstrap.min.js');
//require('./assets/js/animsition.min.js');
require("./assets/owl.carousel/owl.carousel.min.js");

// <!-- Kupon js -->
require("./assets/js/kupon.js");
