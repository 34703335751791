export const commonCopyMixin = {
  methods: {
    async commonCopy(navigate, redirect = false) {
      this.$store.state.redirectCounter = 5;

      this.openCouponModal();

      this.copyToClipboard();

      if (redirect === true && this.$store.state.couponCopyed) {
        this.$store.state.redictCoupon = 5;
      }

      const isIOSDevice = navigator.userAgent.match(/ipad|iphone/i);
      //alert(isIOSDevice);

      if (redirect === true) {
        await this.countDownTimer(navigate);
      }

      if (isIOSDevice === null && redirect) {
        setTimeout(function() {
          if (this.$store.state.couponCopyed) {
            this.$store.state.rtl_mode = 0;
            window.location.href = this.$store.state.coupon_link;
          }
        }, 5000);
      }
    },

    countDownTimer(navigate) {
      if (this.$store.state.redirectCounter > 0) {
        setTimeout(() => {
          this.$store.state.redirectCounter -= 1;
          this.countDownTimer();
        }, 1500);
      } else {
        this.$store.state.redictCoupon = 0;
        this.$store.state.redirectCounter = 5;
        // this.$store.state.rtl_mode = 0;
        if (this.$store.state.couponCopyed)
          window.location.href = this.$store.state.coupon_link;
      }
    },

    openCouponModal() {
      $("#couponOfferModal").modal("show");
      $("#couponOfferModal").modal("show");
    },

    async copyToClipboard(textToCopy = this.textToCopy) {
      //  document.execCommand('copy', false, textToCopy)

      navigator.clipboard.writeText(textToCopy).then(
        () => {
          // clipboard successfully set
          this.$store.state.couponCopyed = 1;

          console.log("success");
        },
        () => {
          // clipboard write failed
          this.$store.state.redictCoupon = 0;
          this.$store.state.couponCopyed = 0;
          console.log("Failed to copy");
        }
      );
      // const copy = async () => {
      //   try {
      //     cliboard.copy(textToCopy)
      //   } catch (e) {
      //     console.error("Failed to copy: ", err);
      // this.$vToastify.success("Coupon not Copied to Clipboard!!");
      //   }
      // }
    },
  },
};
