import Vue from 'vue'
import Vuex from 'vuex'
import coupon from './modules/coupon'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    coupon_link:null,
    rtl_mode:0,
    redirectCounter:5,
    redictCoupon: 0,
    couponCopyed: 0,
    showLoader: false,
    CouponDetail: [],
    offerCoupon: [],
    storeSearch: [],
    popularCoupon: [],
    storeProfileSearch: [],
    topStores:[]
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    coupon
  },
})
