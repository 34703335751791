export const SET_SHOW_LOADER = "SET_SHOW_LOADER";
export const GET_COUPON_DETAILS = "GET_COUPON_DETAILS";
export const GET_OFFER_COUPON_DETAILS = "GET_OFFER_COUPON_DETAILS";
export const GET_STORE_SEARCH_DETAILS = "GET_STORE_SEARCH_DETAILS";
export const GET_POPULAR_COUPON_DETAILS = "GET_POPULAR_COUPON_DETAILS";
export const GET_STORE_SEARCH_PROFILE_DETAILS =
  "GET_STORE_SEARCH_PROFILE_DETAILS";
export const GET_TOP_STORES_DETAILS = "GET_TOP_STORES_DETAILS";
export const STORE_HEADER = "STORE_HEADER";
export const FOOTER = "FOOTER";
